import React from 'react'
import {Form, Input, Button, Radio} from 'antd';
import {LocaleContext} from "../../../context/LocaleContext";
import language from "../../../../data/language.yaml";
import {post} from "../../../services/api";
import {navigate} from "gatsby";
import {Location} from "@reach/router";

class RegisterBusinessForm extends React.Component {
    state = {
        allowSubmit: true,
        errors:      [],
        showForm:    true
    }

    constructor(props) {
        super(props);

        this.onSubmit         = this.onSubmit.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
    }

    onSubmitCallback = (error, response) => {
        if (response.data.status === 'error') {
            this.setState({allowSubmit: true, errors: response.data.errors})
        } else if (response.data.status === 'success' && response.data.errors.length === 0) {
            this.setState({allowSubmit: false, showForm: false});
        }
    }

    onSubmit = event => {
        event.preventDefault();
        this.props.form.validateFieldsAndScroll(this.onValidateCallback);
    }

    onValidateCallback = (errors, values) => {
        if (errors === null) {
            this.setState({allowSubmit: false});
            post('/wp-json/brew/v1/register-a-business/', this.props.form.getFieldsValue(), this.onSubmitCallback);
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;

        let lang    = language.registerBusiness;
        let current = this.context.locale;

        let content = false;
        if (this.state.showForm) {
            content = (<Form onSubmit={this.onSubmit}>
                    { this.state.errors && (
                        <ul>
                            {this.state.errors.map((error, index) => (<li key={"error-" + index}>{error}</li>))}
                        </ul>
                    )}
                    <Form.Item>
                        {getFieldDecorator('business_name', {
                            rules: [{required: true, message: lang.businessName.error[current]}],
                        })(
                            <Input
                                placeholder={lang.businessName[current]}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('business_url', {
                            rules: [{required: true, message: lang.businessWebsite.error[current]}],
                        })(
                            <Input
                                placeholder={lang.businessWebsite[current]}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('location', {
                            rules: [{required: true, message: lang.location.error[current]}],
                        })(
                            <Input
                                placeholder={lang.location[current]}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('name', {
                            rules: [{required: true, message: lang.name.error[current]}],
                        })(
                            <Input
                                placeholder={lang.name[current]}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('telephone', {
                            rules: [{required: true, message: lang.telephone.error[current]}],
                        })(
                            <Input
                                placeholder={lang.telephone[current]}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [{required: true, message: lang.emailAddress.error[current]}],
                        })(
                            <Input
                                type="email"
                                placeholder={lang.emailAddress[current]}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label={lang.businessOffering[current]}>
                        {getFieldDecorator('business_type', {
                            rules: [{required: true, message: lang.businessOffering.error[current]}],
                        })(
                            <Radio.Group>
                                <Radio value="Attraction">{lang.businessOffering.attraction[current]}</Radio>
                                <Radio value="Acommodation">{lang.businessOffering.accommodation[current]}</Radio>
                                <Radio value="Eatery">{lang.businessOffering.eatery[current]}</Radio>
                                <Radio value="Retail/Hire">{lang.businessOffering.retailHire[current]}</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item label={lang.visitWales.label[current]}>
                        {getFieldDecorator('approved', {
                            rules: [{required: true, message: lang.visitWales.error[current]}]
                        })(
                            <Radio.Group>
                                <Radio value="Yes">{lang.visitWales.yes[current]}</Radio>
                                <Radio value="No">{lang.visitWales.no[current]}</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="btn btn-secondary">
                            <span>{lang.submit[current]}</span>
                            <span className="icon arrow"></span>
                        </Button>
                    </Form.Item>
                </Form>
            );
        } else {
            content = <Location>
                {({location}) => {
                    let path = current === 'en' ? 'thank-you' : 'diolch';
                    navigate(location.pathname + path + '/');
                }}
            </Location>
        }

        return content;
    }
}

RegisterBusinessForm.contextType = LocaleContext;

export default Form.create()(RegisterBusinessForm);
