import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import {graphql} from "gatsby";
import PageHeader from "../components/PageHeader";
import {Col, Row} from "antd";
import RegisterBusinessForm from "../components/Forms/RegisterBusinessForm";

class RegisterABusiness extends Component {

    render() {

        const page = this.props.data.wordpressPage;
        const acfFields = this.props.data.wordpressPage.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = page.title;
        }

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        let slides = "";
        let headerImage = "";
        let depth = "";

        if(acfFields.header_images !== null && acfFields.header_images !== undefined) {

            if (acfFields.header_images.length > 1) {
                slides = [];
                acfFields.header_images.forEach(element =>
                    slides.push(element.localFile.childImageSharp.fluid)
                );
            }

            headerImage = acfFields.header_images[0].localFile.childImageSharp.fluid;
            depth = "full";

        } else {

            headerImage = this.props.data.allWordpressAcfOptions.edges[0].node.options.default_header_image.localFile.childImageSharp.fluid;
            depth = "thin";

        }

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
                locale={page.translations.current}
                translations={page.translations}
            >
                <PageHeader
                    title = {acfFields.display_header}
                    videoID = {acfFields.header_video_id}
                    image = {headerImage}
                    slides = {slides}
                    headerColor = {acfFields.header_colour}
                    headerStyle = {acfFields.header_style}
                    back = ""
                    date = ""
                    depth = {depth}
                />
                <div className="intro">
                    <Row gutter={70}>
                        <Col xs={{span: 24}} md={{span: 12}}>
                            <div dangerouslySetInnerHTML={{__html: acfFields.left_column_content}} />
                        </Col>
                        <Col xs={{span: 24}} md={{span: 12}}>
                            <div dangerouslySetInnerHTML={{__html: acfFields.above_form_content}} />
                            <RegisterBusinessForm/>
                        </Col>
                    </Row>
                </div>
            </Wrapper>
        )
    }
}

export const pageQuery = graphql`
  query QueryRegisterABusinessPage($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      slug
      link
      wordpress_id
      wordpress_parent
      title
      translations {
        current
        en
        cy
      }
      acf {
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        
        header_style
        header_colour
        header_video_id
        header_images {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
                        fluid(quality: 80, maxWidth: 1440, maxHeight: 640) {
                            ... GatsbyImageSharpFluid
                        }
            }
          }
        }
        display_header
        show_back_link
        
        left_column_content
        above_form_content
      }
    }
  }
`

export default RegisterABusiness
